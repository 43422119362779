import { PopoverBanner } from './PopupBanner.types'

export const POPOVER_BANNER_DATA: {
  [key: string]: PopoverBanner
} = {
  ru: {
    text: `
      <p><i>«Медуза» — это вы!</i> Уже три года мы работаем благодаря вам, и&nbsp;только для вас. Помогите нам прожить вместе с вами 2025 год!</p>
      <p>Если вы находитесь не в России, оформите ежемесячный донат&nbsp;— а&nbsp;мы сделаем все, чтобы миллионы людей получали наши новости. Мы верим, что независимая информация помогает принимать правильные решения даже в самых сложных жизненных обстоятельствах. Берегите себя!</p>
      `,
    id: 'popup-donate-2025-ru',
    switcher: {
      monthly: 'каждый месяц',
      once: 'один раз'
    },
    button: 'Поддержать',
    variants: {
      monthly: [
        {
          text: '20',
          url: {
            usd:
              ' https://buy.stripe.com/aEU9DNaM94re1vqcNc?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/3cs8zJ8E10aY3DyaF3?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          text: '25',
          url: {
            usd:
              'https://buy.stripe.com/5kA6rBf2p2j6b607sH?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025',
            eur:
              'https://buy.stripe.com/9AQ03dcUh0aY4HCaF0?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025'
          }
        },
        {
          description: 'другая сумма',
          isCustom: true,
          url: {
            usd:
              'https://support.meduza.io/?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025#support',
            eur:
              'https://support.meduza.io/?utm_source=meduza&utm_medium=pop_up&utm_campaign=ny2025#support'
          }
        }
      ]
    }
  },
  en: {
    text: `
      <p><span>Don’t take freedom of speech for granted.<br/></span> Help us fight for it — today.</p>
      <p><span>For over a decade,</span> Meduza has fought Putin’s propaganda <em>while resisting Kremlin pressure.</em> We’ve been outlawed, stripped of our income, and had our website blocked. Yet every day, we continue reporting from Russia, bringing the news to audiences both <em>inside the country and around the world.</em> The events that take place there affect your life, too. </p>
      <p><em>For only €10 a month,</em> you can keep Meduza going.<br/><span>The time to act is now.</span></p>
    `,
    id: 'popup-donate-2025-en',
    switcher: {
      monthly: 'Monthly',
      once: 'One-time'
    },
    button: 'Support',
    variants: {
      monthly: [
        {
          text: '10',
          url: {
            usd:
              'https://buy.stripe.com/8wM8zJ2fDg9WgqkcNe?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=240225',
            eur:
              'https://buy.stripe.com/6oEeY72fDcXKde86oR?utm_source=meduza_en&utm_medium=pop_up&utm_campaign=240225'
          }
        }
      ]
    }
  }
}
