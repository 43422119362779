import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTriggerOnScroll } from '@client/hooks/useTriggerOnScroll'
import { getLang } from '@client/selectors/currentUserSelectors'
import { MaterialContent } from '@client/types/MaterialContent'

import { usePopoverBannerStorage } from './helpers'
import { POPOVER_BANNER_DATA } from './PopoverBanner.data'
import { PopupBanner } from './PopupBanner'

export const PopupBannerContainer = ({
  material,
  parentRef
}: {
  material: MaterialContent
  parentRef: React.RefObject<HTMLDivElement>
}) => {
  const [shouldRender, setShouldRender] = useState(false)
  const { storage, isDismissed } = usePopoverBannerStorage()
  const lang = useSelector(getLang)

  const dispatch = useDispatch()

  const content = POPOVER_BANNER_DATA[lang]

  const isVisible = useTriggerOnScroll(parentRef, 40)

  const isPicture = material.container?.mods?.includes('picture')
  const isStatic = isPicture || material.layout === 'simple'

  useEffect(() => {
    if (!storage || isDismissed) {
      return
    }

    setShouldRender(!isDismissed)
  }, [dispatch, storage, isDismissed])

  return (
    <PopupBanner
      content={content}
      shouldRender={shouldRender}
      setShouldRender={setShouldRender}
      isVisible={isVisible}
      layout={isStatic ? 'simple' : ''}
    />
  )
}
