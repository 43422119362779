import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useBannerInView } from '@client/hooks/useBannerInView'
import { useBannerViewAnalytics } from '@client/hooks/useBannerViewAnalytics'
import { makeClassName } from '@client/utils/makeClassName'
import { sendBannerClickAnalytics } from '@client/utils/sendBannerClickAnalytics'

import { PopoverBanner } from './PopupBanner.types'

import styles from './PopoverBannerContent.module.css'

export const PopoverBannerContent = ({
  content,
  handleDismiss
}: {
  content: PopoverBanner
  handleDismiss: () => void
}) => {
  const { button, variants } = content

  const dispatch = useDispatch()

  const selectedVariant = 'monthly'

  const [currency, setCurrency] = useState<'usd' | 'eur'>('usd')

  const variantsList = variants[selectedVariant]

  const position = 'popup'

  const handleButtonClick = () => {
    handleDismiss()
    sendBannerClickAnalytics(content, position, dispatch)
  }

  const { ref, inView } = useBannerInView()

  useBannerViewAnalytics({
    inView,
    banner: content,
    position,
    dispatch
  })

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.content} />

      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.currency}>
            <button
              type="button"
              className={makeClassName([
                [styles.currencyButton, true],
                [styles.isActive, currency === 'eur']
              ])}
              onClick={() => setCurrency('eur')}
            >
              €
            </button>
            <button
              type="button"
              className={makeClassName([
                [styles.currencyButton, true],
                [styles.isActive, currency === 'usd']
              ])}
              onClick={() => setCurrency('usd')}
            >
              $
            </button>
          </div>
        </div>

        <div className={styles.variantsList}>
          {variantsList.map(({ text, url }, index) => (
            <div className={styles.variantsItem} key={index}>
              <div
                className={styles.variantPrice}
                aria-label={`${currency} ${text}`}
              >
                <div className={currency === 'eur' ? styles.eur : styles.usd} />
              </div>

              <div className={styles.variantsItemButton}>
                <a
                  href={url[currency]}
                  onClick={handleButtonClick}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{button}</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
