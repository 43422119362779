import { useCallback, useEffect, useState } from 'react'

export const useTriggerOnScroll = (
  parentRef: React.RefObject<HTMLDivElement>,
  percent: number
): boolean => {
  const [triggered, setTriggered] = useState(false)

  const handleScroll = useCallback(() => {
    if (!parentRef.current) return

    const { scrollHeight } = parentRef.current
    const triggerPoint = (scrollHeight * percent) / 100

    const currentScroll = window.scrollY + window.innerHeight

    if (currentScroll >= triggerPoint && !triggered) {
      setTriggered(true)
    }
  }, [triggered, percent, parentRef])

  useEffect(() => {
    const currentParent = parentRef.current

    if (!currentParent) {
      return null
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll, parentRef])

  return triggered
}
