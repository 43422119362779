import React from 'react'
export const PopoverDismissIcon = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.144 1.144a1.212 1.212 0 0 1 1.713 0l16 16a1.212 1.212 0 0 1-1.713 1.713l-16-16a1.212 1.212 0 0 1 0-1.713Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.857 1.144c.474.473.474 1.24 0 1.713l-16 16a1.212 1.212 0 0 1-1.713-1.713l16-16a1.212 1.212 0 0 1 1.713 0Z"
      fill="currentColor"
    />
  </svg>
)
