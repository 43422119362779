import React from 'react'
import { makeClassName } from '@client/utils/makeClassName'

import { setPopoverBannerDismissed } from './helpers'
import { PopoverBannerContent } from './PopoverBannerContent'
import { PopoverDismissIcon } from './PopoverDismissIcon'
import { PopoverBanner } from './PopupBanner.types'

import styles from './PopoverBanner.module.css'

export const PopupBanner = ({
  content,
  layout,
  isVisible,
  shouldRender,
  setShouldRender
}: {
  content: PopoverBanner
  isVisible: boolean
  layout: string
  shouldRender: boolean
  setShouldRender: (shouldRender: boolean) => void
}) => {
  const handleDismiss = (days: number) => {
    setPopoverBannerDismissed(days)
    setShouldRender(false)
  }

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.isRendered, shouldRender],
        [styles.isVisible, isVisible],
        [styles[layout], layout]
      ])}
    >
      <div className={styles.container}>
        <div className={styles.panel}>
          <button
            className={styles.dismiss}
            onClick={() => handleDismiss(1)}
            type="button"
          >
            <PopoverDismissIcon />
          </button>
          <PopoverBannerContent
            content={content}
            handleDismiss={() => handleDismiss(3)}
          />
        </div>
      </div>
    </div>
  )
}
