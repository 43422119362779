import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useBannerInView } from '@client/hooks/useBannerInView'
import { useBannerViewAnalytics } from '@client/hooks/useBannerViewAnalytics'
import { useIsRendered } from '@client/hooks/useIsRendered'
import { Lang } from '@client/types'
import { getRandomInt } from '@client/utils/getRandomInt'
import { sendBannerClickAnalytics } from '@client/utils/sendBannerClickAnalytics'

import { DFPBannerData } from './DFPBanner.data'

import styles from './DFPBanner.module.css'

export const DFPBanner = ({ lang }: { lang: Lang }) => {
  const { isRendered } = useIsRendered()
  const [banner, setBanner] = useState(null)

  const dispatch = useDispatch()

  const { ref, inView } = useBannerInView()

  const banners = DFPBannerData[lang]
  const position = 'aside'

  useEffect(() => {
    const randomId = getRandomInt(0, banners.length - 1)

    setBanner(banners[randomId])
  }, [banners, isRendered])

  useBannerViewAnalytics({
    inView,
    banner,
    position,
    dispatch
  })

  const handleBannerClick = () => {
    sendBannerClickAnalytics(banner, position, dispatch)
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        {banner && (
          <a
            className={styles.link}
            href={banner.href}
            target="_blank"
            rel="noreferrer"
            ref={ref}
            onClick={handleBannerClick}
          >
            <img className={styles.image} src={banner.image} alt="" />
          </a>
        )}
      </div>
    </div>
  )
}
